import {fetchOverAutentic,fetchOverJWT} from './fetchOverloads';

export const ApiLogin = async (email,password) => {
    try{
        let body={
            login:email,
            pass:password
        }
        let user=await fetchOverAutentic('user/login',body);
        return user;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}