import React,{useState,useEffect} from 'react';
import man from '../assets/images/dashboard/profile.jpg';
import {Container,Row,Col,Form,FormGroup,Input,Label,Button,TabContent,TabPane} from 'reactstrap';
import {withRouter} from 'react-router-dom';
import {Password, EmailAddress,RememberPassword} from '../constant';
import { toast } from 'react-toastify';
import {ApiLogin} from '../api/user';

const Logins = (props) => {
    const [email, setEmail] = useState("admin");
    const [password, setPassword] = useState("admin55");
    const [loading,setLoading] = useState(false) 
    const [togglePassword,setTogglePassword] = useState(false)

    const [value, setValue] = useState(
        localStorage.getItem('profileURL' || man)
    );
    const [name, setName] = useState(
        localStorage.getItem('Name')
    );

    useEffect(() => {
      
    localStorage.setItem('profileURL', value);
    localStorage.setItem('Name', name);
    }, [value,name]);

    
    const loginWithJwt = async(email,password) => {
      let user=await ApiLogin(email,password);
      if(!user.err){
        setValue(man);
        setName(user.login);
        localStorage.setItem('token', user.token);
        window.location.href = `${process.env.PUBLIC_URL}/dashboard/default`;
      }
      else{
        setTimeout(() => {
            toast.error(user.err);
        }, 200);
      } 
    }

    return (
        <Container fluid={true} className="p-0">
        <Row>
        <Col xs="12">     
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="index.html">
                  <img className="img-fluid for-light" src={require("../assets/images/logo/login.png")} alt=""/>
                  <img className="img-fluid for-dark" src={require("../assets/images/logo/logo_dark.png")} alt=""/>
                </a>
              </div>
              <div className="login-main login-tab"> 
                <TabContent activeTab="jwt" className="content-login">
                  <TabPane  className="fade show" tabId="jwt">
                    <Form className="theme-form">
                      <h4>Sign In</h4>
                      <p>{"Enter your email & password to login"}</p>
                      <FormGroup>
                        <Label className="col-form-label">{EmailAddress}</Label>
                        <Input className="form-control" type="email" required="" onChange={e => setEmail(e.target.value)} defaultValue={email} />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">{Password}</Label>
                        <Input className="form-control" type={togglePassword ?  "text" : "password"} onChange={e => setPassword(e.target.value)} defaultValue={password} required=""/>
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                      </FormGroup>
                      <div className="form-group mb-0">
                        <div className="checkbox ml-3">
                          <Input id="checkbox1" type="checkbox"/>
                          <Label className="text-muted" for="checkbox1">{RememberPassword}</Label>
                        </div>
                        <Button color="primary" className="btn-block" onClick={() => loginWithJwt(email,password)}>Sign in</Button>
                      </div>
                    </Form>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Col>
        </Row>
        </Container>
    );
}

export default withRouter(Logins);