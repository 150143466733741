import React, { Fragment, useState,useRef, Component,useEffect } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
// import { Container, Row, Col, Card, CardBody, CardHeader, TabContent, TabPane, Table } from 'reactstrap'/
import { Container, Row, Col, Card, CardBody, CardHeader,CardFooter, Nav, NavItem, TabContent, TabPane, Modal, ModalHeader, ModalBody, Form, FormGroup, Input, Label, Button, Table } from 'reactstrap'

import { useForm } from 'react-hook-form'
import { newTask } from '../../../redux/task-app/action'
import {WATCH_TASK_LIST} from '../../../redux/actionTypes'
import { useSelector, useDispatch } from 'react-redux'
import SweetAlert from 'sweetalert2'
import {Trash2, EyeOff, Eye, Edit } from 'react-feather';
import {ApiBotGetCategory,ApiBotRemoveCategory,ApiBotPublishCategory,ApiBlogUpdateCategory} from '../../../api/bot';
import { toast } from 'react-toastify';


class CreatedByme extends Component {
  render(){
    const {tasklist,RemoveTask,PublicTask,EditCat} = this.props;
    return(
     <CardBody className="p-0">
       <div className="taskadd">
         <div className="table-responsive table-borderless">
           <Table>
             <thead><tr></tr></thead>
             <tbody>
               {tasklist.length > 0 ?
                 tasklist.map((tasklistdata, index) => {
                   return (
                     <tr key={index}>
                       <td>
                         <h6 className="task_title_0">{tasklistdata.title}</h6>
                         <p className="project_name_0">{tasklistdata.public===1?'Published':'Unpublished'}</p>
                       </td>
                       <td>
                         <p className="task_desc_0"><a href={`https://beta.mrfast.io/store/${tasklistdata.url}`} target="_blank" >{`https://beta.mrfast.io/store/${tasklistdata.url}`}</a></p>
                       </td>
                       <td>
                          <span onClick={()=>EditCat(tasklistdata)}>{<Edit/>}</span>
                          <a style={{marginLeft:"15px"}} onClick  ={() => PublicTask(tasklistdata.id,tasklistdata.public)}>{tasklistdata.public===1?<Eye/>:<EyeOff/>}</a>
                        </td>
                       <td><a onClick={() => RemoveTask(tasklistdata.id)}><Trash2 /></a></td>
                     </tr>
                   )
                 })
                 : <tr><td><div className="no-favourite"><span>No category found</span></div></td></tr>
               }
             </tbody>

           </Table>
         </div>
       </div>
     </CardBody>
    )
 }
}  


const CategoryList = (props) => {
  
  const dispatch = useDispatch()
  const newtaskdata = useSelector(content => content.Taskapp.newtaskdata);
  const [tasklist, setTaskList] = useState([]);   
  const [activeTab, setActiveTab] = useState('1');
  const [addModal, setaddModal] = useState(false);
  const [editModal, setTagModal] = useState(false);
  const [editable, setEditable] = useState({});
  const { register, handleSubmit, errors } = useForm();
  const componentRef = useRef();
  const addToggle = () => { setaddModal(!addModal) }
  const tagToggle = () => { setTagModal(!editModal) }

  
  useEffect(async() => {
    handeleSetList();
  },[])

  const handeleSetList=async()=>{
    let dataCat = await ApiBotGetCategory();
    setTaskList(dataCat);
  }
  
 
  const PublicTask = (taskId,status) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: "Every moment you can change the public status!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then(async(result) => {
      if (result.value) {
        let published=await ApiBotPublishCategory(taskId,!status);
        if(!published.err){
          handeleSetList();
          SweetAlert.fire(
            `${status===1?'Unpublished':'Published'}`,
            `This category has been ${status===1?'unpublished':'published'}`,
            'success'
          );
        }
        else{
          setTimeout(() => {
            toast.error(result.err);
          }, 200);
        }
      }
      else {
        SweetAlert.fire(
          'The action is canceled!'
        )
      }
    })
  }
  const RemoveTask = (taskId) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: "Once deleted, you will not be able to recover this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then(async(result) => {
      if (result.value) {
        let deleted=await ApiBotRemoveCategory(taskId);
        if(!deleted.err){
          handeleSetList();
          SweetAlert.fire(
            'Deleted!',
            'This category has been deleted.',
            'success'
          );
        }
        else{
          setTimeout(() => {
            toast.error(result.err);
          }, 200);
        }
      }
      else {
        SweetAlert.fire(
          'The action is canceled!'
        )
      }
    })
  }

  const EditCat=(cat)=>{
    tagToggle();
    setEditable(cat);
  }
  const EditCatForm=async data => {
    if (data !== '') {
      data.id=editable.id;
      const result =await ApiBlogUpdateCategory(data);
      if(result.err){
        setTimeout(() => {
          toast.error(result.err);
        }, 200);
      }
      else{
        setTimeout(() => {
          toast.success(result.msg);
        }, 200);
        handeleSetList();
        tagToggle();
      }
    } else {
      errors.showMessages();
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent="Project" title="Bot Categories List" />
      <Container fluid={true}>
        <Row>
        <Col xl="9" md="12" className="box-col-12">
              <div className="email-right-aside bookmark-tabcontent">
                <Card className="email-body radius-left">
                  <div className="pl-0">
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Card className="mb-0">
                          <CardHeader className="d-flex">
                            <h6 className="mb-0 f-w-600" >Full List</h6>
                          </CardHeader>
                          <CreatedByme tasklist={tasklist}  EditCat={EditCat} RemoveTask={RemoveTask} PublicTask={PublicTask} ref={componentRef}/>
                        </Card>
                        <Modal className="fade show modal-bookmark" isOpen={editModal} toggle={tagToggle} size="lg">
                          <ModalHeader className="modal-title" toggle={tagToggle}>
                            {"Create Tag"}
                          </ModalHeader>
                          <ModalBody>
                            <Form className="form-bookmark needs-validation" onSubmit={handleSubmit(EditCatForm)}>
                              <div className="form-row">
                                <FormGroup className="col-md-12">
                                  <Label>{"Tag Name"}</Label>
                                  <Input type="text" name="title" defaultValue={editable.title}  innerRef={register({ required: true })}/>
                                </FormGroup>
                                <FormGroup className="col-md-12">
                                  <Label>{"Tag URL"}</Label>
                                  <Input type="text" name="url" defaultValue={editable.url} innerRef={register({ required: true })}/>
                                </FormGroup>
                              </div>
                              <Button color="secondary" type="submit" >Save</Button>
                              <Button color="primary m-l-5" onClick={tagToggle}>Cancel</Button>
                            </Form>
                          </ModalBody>
                        </Modal>
                      </TabPane>
                    </TabContent>
                  </div>
                </Card>
              </div>
            </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default CategoryList;