import {fetchOverAutentic,fetchOverJWT,fetchOverJWTForm} from './fetchOverloads';

export const ApiFolderCreat= async (data) => {
    try{
        let result=await fetchOverJWT('file-manager/folder-add',data);
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}

export const ApiFolderGet= async () => {
    try{
        let result=await fetchOverJWT('file-manager/folder-get',{});
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}


export const ApiFolderRemove= async (title) => {
    try{
        let result=await fetchOverJWT('file-manager/folder-del',{title:title});
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}

export const ApiGetFiles= async (title) => {
    try{
        let result=await fetchOverJWT('file-manager/files-get',{title:title});
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}

export const ApiAddFiles= async (body) => {
    try{
        let result=await fetchOverJWTForm('file-manager/files-add',body);
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}


export const ApiFileRemove= async (body) => {
    try{
        let result=await fetchOverJWT('file-manager/file-del',body);
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}