import React, { Fragment, useState,useEffect,useRef } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Input, Button,Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Home, Folder, Clock, Star, AlertCircle, Trash2, Database, Grid, Upload, PlusSquare, XSquare } from 'react-feather';
import { toast } from 'react-toastify'
import errorImg from '../../../assets/images/search-not-found.png';
import {Hometxt,All,Recent,Starred,Recovery,Deleteds,PricingPlan,TrialVersion,FREE,Selected,Premium,ContactUs,AllFiles,RecentlyOpenedFiles,Folders,EndlessAdmin,Files,Storage,AddNew} from '../../../constant'
import axios from "axios";
import {useForm} from 'react-hook-form'
import {ApiFolderCreat,ApiFolderGet,ApiGetFiles,ApiAddFiles,ApiFolderRemove,ApiFileRemove} from '../../../api/fileManger'
import SweetAlert from 'sweetalert2'
import Copy from 'copy-to-clipboard';


const Filemanager = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [selectedFile, setSelectedFile] = useState(null);   // Initially, no file is selected  
  const [selectedFileSize, setSelectedFileSize] = useState(0);   // Initially, no file is selected  
  const [selectedFileType, setSelectedFileType] = useState("");   // Initially, no file is selected  
  const [searchTerm, setSearchTerm] = useState("");
  const [myfile, setMyFile] = useState([])
  const [myfolder, setMyFolder] = useState([])
  const [openFolded, setOpened] = useState("")
  const [tagModal, setTagModal] = useState(false)
  const [popupFile, setPopupFile] = useState({})

  useEffect(async() => {
     handleSetFolder()
  },[])


  const handeSetPopupFile=(i)=>{
    tagToggle();
    myfile[i].type=myfile[i].type.split("/")[0];
    setPopupFile(myfile[i]);
    setTimeout(()=>{
    },150)
  }

  const tagToggle = () => { setTagModal(!tagModal) }
  
  const handleChange = event => {
    event.preventDefault();
    setSearchTerm(event.target.value);
  };

  const handleSetFolder=async()=>{
    let getFile=await ApiFolderGet();
    if(getFile.length>0)getFile=getFile.sort((a, b) => (a.date < b.date) ? 1 : -1)
    setMyFolder(getFile);
  }
  // eslint-disable-next-line

  const filelist = myfile.filter((data) => {
    if(searchTerm == null)
        return data
    else if(data.f_name.toLowerCase().includes(searchTerm.toLowerCase())){
        return data
    }   
    }).map((data,i)=>{
      return(
        <li className="file-box mb-2" key={i}>
          <div className="file-top">
            <i className={`fa fa-file-${data.type.split('/')[0]==="image" || data.type.split('/')[0]==="video"?"image":"text"}-o txt-${data.type!==""?'info':'success'}`} ></i>
            <i className="fa fa-ellipsis-v f-14 ellips cursor-pointer" onClick={()=>handeSetPopupFile(i)}></i>
          </div>
          <div className="file-bottom">
            <h6>{data.f_name} </h6>
            <p className="mb-1">{data.size} MB</p>
            <p> <b>{"File format"} : </b>{data.type.split("/")[0]}</p>
          </div>
        </li>
      )
    })

  const getFile = () => {
    document.getElementById("upfile").click();
  }

  const onFileChange = event => {
    setSelectedFile(event.target.files[0]);
    setSelectedFileSize(event.target.files[0].size);
    setSelectedFileType(event.target.files[0].type);
  };

  const onFileUpload = async() => {
    if(selectedFile!=="" && openFolded!==""){
      let data=new FormData();
      data.append('title',openFolded);
      data.append('size',selectedFileSize);
      data.append('type',selectedFileType);
      data.append('file',selectedFile);
      const result=await ApiAddFiles(data);
      if(result.err){
        setTimeout(() => {
          toast.error(result.err);
        }, 200);
      }
      else{
        setTimeout(() => {
          toast.success(result.msg);
        }, 200);
        const get_file=await ApiGetFiles(openFolded);
        setMyFile(get_file);
      }
    }
    else{
      setTimeout(() => {
        toast.error('There is not choosen file or folder');
      }, 200);
    }
  };


  const CrateFolder=async(data)=>{
    if(data!==""){
      const result=await ApiFolderCreat(data);
      if(result.err){
        setTimeout(() => {
          toast.error(result.err);
        }, 200);
      }
      else{
        setTimeout(() => {
          toast.success(result.msg);
        }, 200);
        handleSetFolder();
      }
    }
    else{
      errors.showMessages();
    }
  }

  const openFolder=async(title)=>{
    setOpened(title);
    const get_file=await ApiGetFiles(title);
    setMyFile(get_file);
  }
  
  const setFolder=async()=>{
    const get_file=await ApiGetFiles(openFolded);
    setMyFile(get_file);
  }

  const onFolderRemove=async()=>{
    if(openFolded==="")return;
    SweetAlert.fire({
      title: 'Are you sure?',
      text: "Once deleted, you will not be able to recover this folder!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then(async(result) => {
      if(result.value){
        let delResult=await ApiFolderRemove(openFolded);
        if (delResult.success) {
          SweetAlert.fire(
            'Deleted!',
            'The folder has been deleted.',
            'success'
          );
          handleSetFolder();
          setOpened("");
          setMyFile([]);
        }
        else{
          setTimeout(() => {
            toast.error(delResult.err);
          }, 200);
        }
      }
      else {
        SweetAlert.fire(
          'Your imaginary folder is safe!'
        )
      }
    })
  }

  const onRemoveFile=async()=>{
    SweetAlert.fire({
      title: 'Are you sure?',
      text: "Once deleted, you will not be able to recover this file!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then(async(result) => {
      if(result.value){
        let body={
          f_name:popupFile.f_name,
          folder:openFolded
        }
        let delResult=await ApiFileRemove(body);
        if (delResult.success) {
          SweetAlert.fire(
            'Deleted!',
            'The file has been deleted.',
            'success'
          );
          tagToggle();
          setFolder();
        }
        else{
          setTimeout(() => {
            toast.error(delResult.err);
          }, 200);
        }
      }
      else {
        SweetAlert.fire(
          'Your imaginary file is safe!'
        )
      }
    })
  }
 
  return (
    <Fragment>
      <Breadcrumb parent="Apps" title="File Manager" />
      <Container fluid={true}>
        <Row>
        <Col xl="3" className="box-col-6 pr-0 file-spacing">
            <div className="file-content">
              <Card>
                <CardHeader>
                  <div className="media">
                    <div className="media-body text-right">
                        <Form className="d-inline-flex" onSubmit={handleSubmit(CrateFolder)}>
                          <FormGroup  className="mb-0 mr-2">
                            <Input className="form-control" type="text" name="folderName" placeholder="Folder Name" innerRef={register({ required: true })}/>
                          </FormGroup>
                          <FormGroup>
                            <Button className="btn ml-1 d-flex align-items-center" color="outline-primary"><PlusSquare />Create</Button>
                          </FormGroup>
                        </Form>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="file-manager">
                      <h6 className="mt-4">{Folders}</h6>
                        <ul className="folder">
                          {myfolder.map((folder,i)=>(
                            <li key={i} className={`folder-box ml-0 mt-3 ${folder.title===openFolded?'bg-dark':''} fileMengerFolder`}   onClick={()=>openFolder(folder.title)} on>
                              <div className="media"><i className="fa fa-folder f-36 txt-warning"></i>
                                <div className="media-body ml-3">
                                  <h6 className="mb-0">{folder.title}</h6>
                                  <p>{folder.count} items, {folder.cdate}</p>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                  </CardBody>
              </Card>
            </div>
        </Col>
          <Col xl="9" md="12" className="box-col-12">
            <div className="file-content">
              <Card>
                <CardHeader>
                  <div className="media">
                    <Form className="form-inline">
                      <FormGroup>
                        <i className="fa fa-search"></i>
                        <Input
                          className="form-control-plaintext"
                          type="text"
                          value={searchTerm}
                          onChange={(e) => handleChange(e)}
                          placeholder="Search..." />
                      </FormGroup>
                    </Form>
                    <div className="d-flex align-items-center w-50 justify-content-center">
                      <h5>{openFolded!==""?openFolded:'No choosen folder'}</h5>
                    </div>
                    <div className="media-body text-right d-flex flex-wrap">
                      <Form className="d-inline-flex">
                        <div className="btn btn-primary" onClick={getFile}> <PlusSquare />{AddNew}</div>
                        <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                          <input id="upfile"  type="file" onChange={(e) => onFileChange(e)} />
                        </div>
                      </Form>
                      <div className="btn btn-outline-primary ml-1" onClick={onFileUpload} ><Upload />{"Upload"}</div>
                      <div className="btn btn-outline-danger ml-0 mr-2  mt-2 w-100" onClick={onFolderRemove}><XSquare/>{"Remove Folder"}</div>
                    </div>
                  </div>
                </CardHeader>
                {filelist.length > 0 ?
                  <CardBody className="file-manager">
                    <h6 className="mt-4" >Files of <b>{openFolded}</b></h6>
                    <ul className="files">
                      {filelist}
                    </ul>
                    <Modal className="fade show modal-bookmark" isOpen={tagModal}  size="lg">
                          <ModalHeader className="modal-title" >
                            {`File - ${popupFile.f_name}`}
                          </ModalHeader>
                          <ModalBody>
                            {popupFile.type==='image'
                            ?
                              <img src={popupFile.link} alt="header" className="mb-3" style={{maxWidth:"100%"}}/>
                            :
                              <iframe src={popupFile.link} frameBorder="0" className="inclusivIfrem mb-3"></iframe>
                            }
                            <Form className="form-bookmark needs-validation d-flex">
                              <Input  value={popupFile.link} type="text" readOnly={true}/>
                              <div className="btn btn-outline-success ml-1" onClick={()=>Copy(popupFile.link)}>{"Copy"}</div>
                              <div className="btn btn-outline-primary ml-1" onClick={tagToggle}>{"Cancel"}</div>
                              <div className="btn btn-outline-danger  ml-1" onClick={onRemoveFile}>{"Remove"}</div>
                            </Form>
                          </ModalBody>
                    </Modal>
                  </CardBody>
                  :
                  <img className="img-fluid m-auto" src={errorImg} alt="" />
                }
              </Card>
            </div>

          </Col>
        </Row>
      </Container>

    </Fragment>
  );
}

export default Filemanager;