import {fetchOverAutentic,fetchOverJWT,fetchOverJWTForm} from './fetchOverloads';

//CATRE

export const ApiBlogAddPost = async (body) => {
    try{
        let result=await fetchOverJWTForm('blog/add-post',body);
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}
export const ApiBlogUpdatePost = async (body) => {
    try{
        let result=await fetchOverJWTForm('blog/update-post',body);
        return result;  
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}

export const ApiBlogDelPost = async (id) => {
    try{
        let result=await fetchOverJWT('blog/del-post',{id:id});
        return result;  
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}


export const ApiBlogGetAll = async () => {
    try{
        let result=await fetchOverJWT('blog/get-post-all',{});
        return result;  
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}


export const ApiBlogAddTag = async (title) => {
    try{
        let result=await fetchOverJWT('blog/add-tag',{title:title});
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}

export const ApiBlogGetTag = async () => {
    try{
        let result=await fetchOverJWT('blog/get-tag',{});
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}

export const ApiBlogDelTag = async (id) => {
    try{
        let result=await fetchOverJWT('blog/del-tag',{id:id});
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}

export const ApiBlogGetOne = async (id) => {
    try{
        let result=await fetchOverJWT('blog/get-post-one',{id:id});
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}

export const ApiBlogTagByList = async (list) => {
    try{
        let result=await fetchOverJWT('blog/get-tags-by-list',{list:list});
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}

