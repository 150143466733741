import React, { Fragment,useState,useEffect } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
// import Dropzone from 'react-dropzone-uploader'
import {Container,Row,Col,Card,CardBody,Form,FormGroup,Label,Input,Button} from 'reactstrap'
import DatePicker from "react-datepicker";
import {useForm} from 'react-hook-form'
import {withRouter,Link} from 'react-router-dom'
import {Add,Cancel} from '../../../constant'
import Ckeditor from 'react-ckeditor-component'
import { serialize } from 'object-to-formdata';
import {ApiBotAdd,ApiBotGetCategory} from '../../../api/bot';
import { toast } from 'react-toastify';

const NewBot = (props) => {

    const { register, handleSubmit, errors } = useForm();
    const [startDate, setstartDate] = useState(new Date());
    const [txtOff, setTxtOff] = useState();
    const [txtOverwrite, setTxtOverwrite] = useState();
    const [txtTutorial, setTxtTutorial] = useState();
    const [txtUpdate, setTxtUpdate] = useState();
    const [categryies, setTaskList] = useState([]);
    
    useEffect(async() => {
      handeleSetList();
    },[])
  
    const handeleSetList=async()=>{
      let dataCat = await ApiBotGetCategory();
      setTaskList(dataCat);
    }
    

    const handleStartDate = date => {
      setstartDate(date);
    };

    const handleSetTxt = e => {
      setTxtOff(e.editor.getData());
    };
    const handleSetTxtOverwrite = e => {
      setTxtOverwrite(e.editor.getData());
    };
    const handleSetTxtTutorial= e => {
      setTxtTutorial(e.editor.getData());
    };
    const handleSetTxtUpdate = e => {
      setTxtUpdate(e.editor.getData());
    };
    
    // const handleGetCategory = async() => {
    //   let categor=await ApiBotGetCategory();
    //   setCategories(categor);
    // };


    const AddProject =async data => {
      if (data !== '') {
        data.ctr_date=startDate;
        data.txtOff=txtOff;
        data.txtOverwrite=txtOverwrite;
        data.txtTutorial=txtTutorial;
        data.txtUpdate=txtUpdate;
        let newForm=serialize(data);
        let result=await ApiBotAdd(newForm);
        if(result.err){
          setTimeout(() => {
            toast.error(result.err);
          }, 200);
        }
        else{
          setTimeout(() => {
            toast.success(result.msg);
          }, 200);
          props.history.push(`./old-bot/${result.id}`);
        }
      } else {
        errors.showMessages();
      }
    };

    return (
        <Fragment>
        <Breadcrumb parent="Bot" title="Create Bot" /> 
        <Container fluid={true}>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Form className="theme-form" onSubmit={handleSubmit(AddProject)}>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Bot title</Label>
                            <Input className="form-control" type="text"  name="title" placeholder="Bot titile *" innerRef={register({ required: true })} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Bot URL</Label>
                            <Input className="form-control" type="text" name="url" placeholder="Bot URL" innerRef={register({ required: true })}/>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="4">
                          <FormGroup>
                            <Label>Bot Status</Label>
                            <Input type="select" name="status" placeholder="Select Status" className="form-control digits" innerRef={register({ required: true })}>
                              <option value="0">Unpublish</option>
                              <option value="1">Publish</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup>
                            <Label>Bot Type</Label>
                            <Input type="select" name="type" placeholder="Select Type" className="form-control digits" innerRef={register({ required: true })}>
                              <option value="actions">Actions</option>
                              <option value="extractions">Extractions</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup>
                            <Label>Bot Category</Label>
                            <Input type="select" name="category" placeholder="Select Category" className="form-control digits" innerRef={register({ required: true })}>
                              {/* <option  disabled selected>Select Category</option> */}
                              {categryies.lenght !== 0 ?
                                categryies.map((category, index) => {
                                  return (<option key={index} value={category.id}>{category.title}</option>)
                                })
                                :<option value="0"  disabled>No category found</option>
                              }
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="4">
                          <FormGroup>
                            <Label>Create Date</Label>
                            <DatePicker className="datepicker-here form-control"  selected={startDate} onChange={handleStartDate} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Bot Describtion</Label>
                            <Input  type="textarea" className="form-control" name="description" rows="3" innerRef={register({ required: true })}/>
                            {/* <span style={{ color: "red" }}>{errors.description && 'Describtion is required'}</span> */}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                              <FormGroup>
                                <Label>Overview</Label>
                                <Ckeditor
                                  activeclassName="p10"
                                  content={txtOverwrite}
                                  events={{
                                    "change":handleSetTxtOverwrite
                                  }}
                                />
                              </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                              <FormGroup>
                                <Label>Tutorial</Label>
                                <Ckeditor
                                  activeclassName="p10"
                                  content={txtTutorial}
                                  events={{
                                    "change":handleSetTxtTutorial
                                  }}
                                />
                              </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                              <FormGroup>
                                <Label>Updates</Label>
                                <Ckeditor
                                  activeclassName="p10"
                                  content={txtUpdate}
                                  events={{
                                    "change":handleSetTxtUpdate
                                  }}
                                />
                              </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                              <FormGroup>
                                <Label>Log Out Overview</Label>
                                <Ckeditor
                                  activeclassName="p10"
                                  content={txtOff}
                                  events={{
                                    "change":handleSetTxt
                                  }}
                                />
                              </FormGroup>
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col>
                          <FormGroup>
                            <Label>{UploadProjectFile}</Label>
                                <Dropzone
                                    getUploadParams={getUploadParams}
                                    onChangeStatus={handleChangeStatus}
                                    maxFiles={1}
                                    multiple={false}
                                    canCancel={false}
                                    inputContent="Drop A File"
                                    styles={{
                                        dropzone: { width: '100%', height: 50 },
                                        dropzoneActive: { borderColor: 'green' },
                                    }}
                                />
                          </FormGroup>
                        </Col>
                      </Row> */}
                      <Row>
                        <Col>
                          <FormGroup className="mb-0">
                              <Button color="success" className="mr-3">{Add}</Button>
                              <Link to={`${process.env.PUBLIC_URL}/app/bot/bot-list`}>
                                  <Button color="danger">{Cancel}</Button>
                              </Link>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Fragment>
    );
}

export default withRouter(NewBot);