import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, Progress, TabContent, TabPane } from 'reactstrap'
import { useSelector } from 'react-redux';
import {Issues,Resolved,Comment,Done,} from '../../../constant';
import {ApiBotGetAll} from '../../../api/bot';
import { Link } from 'react-router-dom';

const BotList = (props) => {
  
  const [activeTab,setActiveTab] = useState("1");
  const [allProject, setBots] = useState([]);
  
  useEffect(async() => {
    handeleSetBotsAll();
  },[])

  const handeleSetBotsAll=async()=>{
    let dataBots = await ApiBotGetAll();
    setBots(dataBots);
  }

  return (
    <Fragment>
      <Breadcrumb parent="Project" title="Bot List" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      {allProject.map((item, i) =>
                        <Col sm="4" className="mt-4" key={i}>
                          <Link to={`./old-bot/${item.id}`} style={{color:"unset"}}>
                            <div className="project-box">
                              <div className="badgeBox">
                                <span className="badge badge-dark">{item.type}</span>
                                <span className={`badge ${item.publish === 1 ? 'badge-success' : 'badge-primary'}`}>{item.publish===1?"Published":"Unpublished"}</span>
                              </div>
                              <h6>{item.title}</h6>
                              <div className="media">
                                <img className="img-20 mr-1 rounded-circle" src={item.cat_img} />
                                <div className="media-body">
                                  <p>{item.cat_title}</p>
                                </div>
                              </div>
                              <p style={{height:"66px",overflow:"hidden"}}>{item.description}</p>
                              <Row className="details">
                                <Col xs="6"><span>View</span></Col>
                                <Col xs="6" className={item.badge === "Done" ? 'text-success' : 'text-primary'}>{item.view}</Col>
                                <Col xs="6"> <span>Free Usage</span></Col>
                                <Col xs="6" className={item.badge === "Done" ? 'text-success' : 'text-primary'}>{item.use_free}</Col>
                                <Col xs="6"> <span>Payed Usage</span></Col>
                                <Col xs="6" className={item.badge === "Done" ? 'text-success' : 'text-primary'}>{item.use_pay}</Col>
                              </Row>
                              {/* <div className="customers">
                                <ul>
                                  <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../../assets/images/${item.customers_img1}`)} alt="" /></li>
                                  <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../../assets/images/${item.customers_img2}`)} alt="" /></li>
                                  <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../../assets/images/${item.customers_img3}`)} alt="" /></li>
                                  <li className="d-inline-block ml-2">
                                    <p className="f-12">{`+${item.like} More`}</p>
                                  </li>
                                </ul>
                              </div> */}
                              <div className="project-status mt-4">
                                <div className="media mb-0">
                                  <p>{((item.use_free+item.use_pay)*100)/item.view}%</p>
                                  <div className="media-body text-right"><span>Total Usage</span></div>
                                </div>
                                {((item.use_free+item.use_pay)*100)/item.view === "100" ?
                                  <Progress className="sm-progress-bar" color="success" value={((item.use_free+item.use_pay)*100)/item.view} style={{ height: "5px" }} />
                                  :
                                  <Progress className="sm-progress-bar" striped color="primary" value={((item.use_free+item.use_pay)*100)/item.view} style={{ height: "5px" }} />
                                }
                              </div>
                            </div>
                          </Link>
                        </Col>
                      )}
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default BotList;