import React, { Fragment, useState,useRef, Component,useEffect } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, CardHeader,CardFooter, Nav, NavItem, TabContent, TabPane, Modal, ModalHeader, ModalBody, Form, FormGroup, Input, Label, Button, Table } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { newTask, removeTask } from '../../redux/task-app/action'
import {WATCH_TASK_LIST} from '../../redux/actionTypes'
import { useSelector, useDispatch } from 'react-redux'
import SweetAlert from 'sweetalert2'
import { Printer, Link, MoreHorizontal, Trash2, PlusCircle, CheckCircle } from 'react-feather';
import ReactToPrint from "react-to-print";
import {AddTask,TaskTitle,Collection,General,Description,Save,Cancel,Views,CreatedByMe,TodayTasks,DelayedTasks,UpcomingTasks,ThisWeekTask,ThisMonthTasks,AssignedToMe,MyTasks,Tags,Newsletter,Business,Holidays,Print,NoTasksFound,Notification,MARKJENCO,MARKJENCOEMAIL,NewTask,NoTaskDueToday, Card_Footer} from "../../constant";
import { toast } from 'react-toastify';
import {ApiBlogAddTag,ApiBlogGetTag,ApiBlogDelTag} from '../../api/blog';



const BlogTags = (props) => {

  const { register, handleSubmit, errors } = useForm();
  const [tagModal, setTagModal] = useState(false)
  const [tagList, setTagList] = useState([])
  const [tagOpen, setTagOpen] = useState({})
  const tagToggle = () => { setTagModal(!tagModal) }

  useEffect(() => {
    handelUpdateTagList();
  },[])
  // My
  const handelUpdateTagList=async()=>{
    const tags=await ApiBlogGetTag();
    setTagList(tags);
  }
  const handelOpenInfo=(title,id)=>{
    const obj={title:title,id:id};
    setTagOpen(obj);
  }

  const AddTag =async data => {
    if (data !== '') {
      const result =await ApiBlogAddTag(data.title);
      if(result.err){
        setTimeout(() => {
          toast.error(result.err);
        }, 200);
      }
      else{
        setTimeout(() => {
          toast.success(result.msg);
        }, 200);
        handelUpdateTagList();
      }
    } else {
      errors.showMessages();
    }
  };

  const RemoveTask = (taskId) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: "Once deleted, you will not be able to recover this tag!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then(async(result) => {
      if(result.value){
        let delResult=await ApiBlogDelTag(taskId);
        if (delResult.success) {
          SweetAlert.fire(
            'Deleted!',
            'The Tag has been deleted.',
            'success'
          );
          setTagOpen({});
          handelUpdateTagList();
        }
        else{
          setTimeout(() => {
            toast.error(delResult.err);
          }, 200);
        }
      }
      else {
        SweetAlert.fire(
          'Your imaginary file is safe!'
        )
      }
    })
  }
  
  return (
    <Fragment>
      <Breadcrumb parent="Apps" title="Tags" />
      <Container fluid={true}>
        <div className="email-wrap bookmark-wrap">
          <Row>
            <Col xl="3" className="box-col-6">
              <div className="email-left-aside">
                <Card>
                  <CardBody>
                    <div className="email-app-sidebar left-bookmark">
                      <Nav className="main-menu" role="tablist">
                        <li>
                          <hr />
                        </li>
                        <NavItem><span className="main-title">Tags List<span className="pull-right" onClick={tagToggle}><PlusCircle/></span></span></NavItem>
                        <Modal className="fade show modal-bookmark" isOpen={tagModal} toggle={tagToggle} size="lg">
                          <ModalHeader className="modal-title" toggle={tagToggle}>
                            {"Create Tag"}
                          </ModalHeader>
                          <ModalBody>
                            <Form className="form-bookmark needs-validation" onSubmit={handleSubmit(AddTag)}>
                              <div className="form-row">
                                <FormGroup className="col-md-12">
                                  <Label>{"Tag Name"}</Label>
                                  <Input type="text" name="title" innerRef={register({ required: true })}/>
                                </FormGroup>
                              </div>
                              <Button color="secondary" type="submit  ">{Save}</Button>
                              <Button color="primary m-l-5" onClick={tagToggle}>{Cancel}</Button>
                            </Form>
                          </ModalBody>
                        </Modal>
                        {tagList.map((tag,i)=>(
                          <NavItem key={i}><a href="#javaScript" onClick={() => handelOpenInfo(tag.title,tag.id)} ><span className="title"> {tag.title}</span></a></NavItem>
                        ))}
                      </Nav>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col xl="9" md="12" className="box-col-12">
              <div className="email-right-aside bookmark-tabcontent">
                <Card className="radius-left">
                    {Object.keys(tagOpen).length>0 ? 
                      <div className="pl-0">
                        <CardHeader>
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <h6 className="mb-0 f-w-600">{tagOpen.title}</h6>
                            <Button color="danger" onClick={()=>RemoveTask(tagOpen.id)}>Delete</Button>
                          </div>
                        </CardHeader>
                      </div>
                    : '' }
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default BlogTags;