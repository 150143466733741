import base64 from 'base-64';

// const API_URL="http://localhost:4000/api-admin";
const API_URL="https://api.mrfast.io/api-admin";
const Username="M8RF5A9S2T";
const Password="mr#fast^v.t9"

export const fetchOverAutentic=async(link,body)=>{
    return fetch(`${API_URL}/${link}`,{
        method:"POST",
        headers:{
            'Content-type':'application/json',    
            'Authorization':`Basic ${base64.encode(`${Username}:${Password}`)}`,
        },
        body:JSON.stringify(body)
    })
    .then(resp=>{return resp.json()})
    .then(data=>{
        return data;
    })
    .catch(err=>{
        let data=new Object()
        data.err=err;
        return data;
    })
}

export const fetchOverJWT=async(link,body)=>{
    return fetch(`${API_URL}/${link}`,{
        method:"POST",
        headers:{
            'Content-type':'application/json',    
            'Authorization':`${localStorage.getItem('token')}`,
        },
        body:JSON.stringify(body)
    })
    .then(resp=>{return resp.json()})
    .then(data=>{
        return data;
    })
    .catch(err=>{
        let data=new Object()
        data.err=err;
        return data;
    })
}

export const fetchOverJWTForm=  async(link,body)=>{
    return fetch(`${API_URL}/${link}`,{
        method:"POST",
        headers:{
            'Authorization':`${localStorage.getItem('token')}`,
        },
        body:body
    })
    .then(resp=>{return resp.json()})
    .then(data=>{
        return data;
    })
    .catch(err=>{
        let data=new Object()
        data.err=err;
        return data;
    })
}