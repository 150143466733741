import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {withRouter,Link} from 'react-router-dom'
import blog5 from "../../assets/images/blog/blog-5.jpg";
import { Container, Row, Col, Card, Media } from "reactstrap"
import {ApiBlogGetAll} from '../../api/blog'

const BlogDetail = () => {
    const [blogs,setBlog]=useState([]);

    useEffect(() => {
        handelSetBlog();
    },[])

    const handelSetBlog=async()=>{
        const data=await ApiBlogGetAll();
        console.log(data)
        setBlog(data);
    }


    return (
        <Fragment>
            <Breadcrumb parent="Blog" title="Blog Details" />
            <Container fluid={true}>
                <Row>
                    {blogs.map((blog,i)=>(
                        <Col md="6" xl="3 box-col-6 xl-50">
                            <Link key={i} to={`${process.env.PUBLIC_URL}/app/blog/blog-one/${blog.id}`}>
                                <Card>
                                    <div className="blog-box blog-grid text-center">
                                        <Media className="img-fluid top-radius-blog" src={blog.img} alt="" />
                                        <div className="blog-details-main">
                                            <ul className="blog-social">
                                                <li className="digits">{blog.date}</li>
                                                <li className="digits">{blog.publish===0?'Unpublished':'Published'}</li>
                                            </ul>
                                            <hr/>
                                            <h6 className="blog-bottom-details">{blog.title}</h6>
                                        </div>
                                    </div>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            </Container>
        </Fragment>
    );
};

export default BlogDetail;