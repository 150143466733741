import {fetchOverAutentic,fetchOverJWT,fetchOverJWTForm} from './fetchOverloads';
//CATRE
export const ApiBotAddCategory = async (body) => {
    try{
        let result=await fetchOverJWTForm('bot/category-add',body);
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}

export const ApiBotGetCategory = async () => {
    try{
        let result=await fetchOverJWT('bot/category-get',{});
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}

export const ApiBotRemoveCategory = async (id) => {
    try{
        let result=await fetchOverJWT('bot/category-del',{id:id});
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}

export const ApiBotPublishCategory = async (id, status) => {
    try{
        let result=await fetchOverJWT('bot/category-publish',{id:id, status:status});
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}
export const ApiBlogUpdateCategory = async (body) => {
    try{
        let result=await fetchOverJWT('bot/category-update',body);
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}



// END CATEGORY

export const ApiBotAdd = async (body) => {
    try{
        let result=await fetchOverJWTForm('bot/add',body);
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}

export const ApiBotGetAll = async () => {
    try{
        let result=await fetchOverJWT('bot/get-all',{});
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}

export const ApiBotGetOne = async (id) => {
    try{
        let result=await fetchOverJWT('bot/get-bot-by-id',{id:id});
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}


export const ApiBotEdit = async (body) => {
    try{
        let result=await fetchOverJWTForm('bot/edit',body);
        return result;
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}

export const ApiBlogDelBot = async (id) => {
    try{
        let result=await fetchOverJWT('bot/del-bot',{id:id});
        return result;  
    }
    catch(err){
        let result=new Object();
        result.err='Something went wrong please try again';
        return result;
    }
}