import React, { Fragment,useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import Dropzone from 'react-dropzone-uploader'
import {Container,Row,Col,Card,CardBody,Form,FormGroup,Label,Input,Button} from 'reactstrap'
import DatePicker from "react-datepicker";
import {useForm} from 'react-hook-form'
import {addNewProject} from '../../../redux/project-app/action'
import { useDispatch } from 'react-redux';
import {withRouter,Link} from 'react-router-dom';
import { toast } from 'react-toastify';
import {ProjectTitle,ClientName,ProjectRate,ProjectStatus,ProgressLevel,ProjectSize,Small,Medium,Big,StartingDate,EndingDate,EnterSomeDetails,UploadProjectFile,Add,Cancel,Done,Doing} from '../../../constant'
import {ApiBotAddCategory} from '../../../api/bot';

const NewBotCategory = (props) => { 

    const dispatch = useDispatch()
    const { register, handleSubmit, errors } = useForm();

    const AddProject =async data => {
      if (data !== '') {
        let body=new FormData();
        body.append('title', data.title);
        body.append('url', data.url);
        body.append('pic', data.file1[0]);
        let result=await ApiBotAddCategory(body);
        if(result.err){
            setTimeout(() => {
              toast.error(result.err);
            }, 200);
          }
        else{
            setTimeout(() => {
              toast.success(result.msg);
            }, 200);
        }
      } else {
        errors.showMessages();
      }
    };

    return (
        <Fragment>
        <Breadcrumb parent="Project" title="Create Bot Category" /> 
        <Container fluid={true}>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Form className="theme-form" onSubmit={handleSubmit(AddProject)}>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Category Name</Label>
                            <Input className="form-control" type="text"  name="title" placeholder="Category name *" innerRef={register({ required: true })} />
                            <span style={{ color: "red" }}>{errors.title && 'Title is required'}</span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Category URL</Label>
                            <Input className="form-control" type="text"  name="url" placeholder="Category URL *" innerRef={register({ required: true })} />
                            <span style={{ color: "red" }}>{errors.title && 'URL is required'}</span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Upload category icon</Label>
                            <Input className="form-control" type="file"  name="file1"  innerRef={register({ required: true })} />
                            <span style={{ color: "red" }}>{errors.title && 'Icon required'}</span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup className="mb-0">
                              <Button color="success" className="mr-3">{Add}</Button>
                              <Link to={`${process.env.PUBLIC_URL}/app/bot/new-category`}>
                              <Button color="danger">{Cancel}</Button>
                              </Link>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Fragment>
    );
}

export default withRouter(NewBotCategory);