import { Home, Airplay, Box, FolderPlus, Command, Cloud, FileText, Server, BarChart, Users, Layers, ShoppingBag, List, Mail, MessageCircle, GitPullRequest, Monitor, Heart, Clock, Zap, CheckSquare, Calendar, Image, Film, HelpCircle, Radio, Map, Edit, Sunrise, Package } from 'react-feather'
export const MENUITEMS = [

    {
        menutitle:"Applications",
        menucontent:"Ready to use Apps",
        Items:[
            {
                title: 'Bots',icon:Box, type: 'sub',badge: "badge badge-danger",active:false, children: [
                    { path: `${process.env.PUBLIC_URL}/app/bot/bot-list`, type: 'link', title: 'Bot List' },
                    { path: `${process.env.PUBLIC_URL}/app/bot/categories-list`, type: 'link', title: 'Categories List' },
                    { path: `${process.env.PUBLIC_URL}/app/bot/new-bot`, type: 'link', title: 'New Bot' },
                    { path: `${process.env.PUBLIC_URL}/app/bot/new-category`, type: 'link', title: 'New Category' }
                ]
            },
            {
                title: 'Blog',icon:Film, type: 'sub',badge: "badge badge-danger",active:false, children: [
                    { path: `${process.env.PUBLIC_URL}/app/blog/blog-list`, type: 'link', title: 'Post List' },
                    { path: `${process.env.PUBLIC_URL}/app/blog/add-blog`, type: 'link', title: 'Add Post' },
                    { path: `${process.env.PUBLIC_URL}/app/blog/add-tag`, type: 'link', title: 'Tag' },
                ]
            },
            { path: `${process.env.PUBLIC_URL}/app/file-manager`,icon:GitPullRequest, title: 'File Manager', type: 'link' },
        ]
    },
   
]