import React, { Fragment,useState,useEffect } from 'react';
import {withRouter,Link} from 'react-router-dom'
import Breadcrumb from '../../layout/breadcrumb'
import Ckeditor from 'react-ckeditor-component'
import { Typeahead } from 'react-bootstrap-typeahead';
import {useForm} from 'react-hook-form'
import Dropzone from 'react-dropzone-uploader'
import { serialize } from 'object-to-formdata';
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button } from "reactstrap"
import {PostEdit,Title,Type,Category,Content,Post,Discard,Text,Audio,Video,Image} from "../../constant";
import { toast } from 'react-toastify';
import {ApiBlogAddPost,ApiBlogGetTag} from '../../api/blog.jsx'

const BlogPost = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [tags, setTags] = useState("");
  const [content, setContent] = useState("");
  const [pic, setPic] = useState("");
  const [tagList, setTag] = useState();

  useEffect(() => {
    handelUpdateTagList();
  },[])

  const handelUpdateTagList=async()=>{
    const tags=await ApiBlogGetTag();
    setTag(tags);
  }

  const handleSetTag=(selected)=>{
    let tags=new Array();
    for(let i=0;i<selected.length;i++){
      tags.push(`-${selected[i].id}-`)
    }
    let tagsStr=tags.join(",");
    setTags(tagsStr);
  }

  const handleSetContent=(e)=>{
    setContent(e.editor.getData())
  }
  const handleChangeStatus=(e)=>{
    setPic(e.file);
  }
  const AddPost =async data => {
    if (data !== '') {
      if(pic==="" || content==="")return setTimeout(() => { toast.error('Fill All Reuired Field'); }, 200);
      data.tags=tags;
      data.content=content;
      const newForm=serialize(data);
      newForm.append('pic', pic);
      let result=await ApiBlogAddPost(newForm);
      if(result.err){
        setTimeout(() => {
          toast.error(result.err);
        }, 200);
      }
      else{
        setTimeout(() => {
          toast.success(result.msg);
        }, 200);
        props.history.push(`./blog-one/${result.id}`);
      }
    }
    else {
      errors.showMessages();
    }
  }
  return (
    <Fragment>
      <Breadcrumb parent="Blog" title="Add Post" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="add-post">
                <Form className="row needs-validation" onSubmit={handleSubmit(AddPost)}>
                  <Col sm="12">
                    <FormGroup>
                      <Label for="validationCustom01">{Title}:</Label>
                      <Input className="form-control" name="title" id="validationCustom01" type="text" placeholder="Post Title" innerRef={register({ required: true })} />
                    </FormGroup>
                    <FormGroup>
                      <Label for="validationCustom02">URL:</Label>
                      <Input className="form-control" name="url" id="validationCustom02" type="text" placeholder="Post URL" innerRef={register({ required: true })} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Header Text</Label>
                        <Input  type="textarea" className="form-control" name="headerText" rows="3" innerRef={register({ required: true })}/>
                    </FormGroup>
                    <FormGroup>
                      <div className="col-form-Label">{Category}:
                            <Typeahead
                              onChange={(selected) => {
                                handleSetTag(selected);
                              }}
                              id="multiple-typeahead"
                              className="mt-2"
                              clearButton
                              // defaultSelected={tagList.slice(0, 5)}
                              labelKey="title"
                              multiple
                              options={tagList}
                              placeholder="Select Your Name...."
                            />
                      </div>
                    </FormGroup>
                    <div className="email-wrapper">
                      <div className="theme-form">
                        <FormGroup>
                          <Label>{Content}:</Label>
                          <Ckeditor 
                            activeclassName="p10"
                            content={content}
                            events={{
                              "change":handleSetContent
                            }}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </Col>
                  <Col sm="12">
                    <div className="m-0 dz-message needsclick">
                      <Dropzone
                        onChangeStatus={handleChangeStatus}
                        maxFiles={1}
                        multiple={false}
                        canCancel={false}
                        required={true}
                        acceptedFiles='image/*'
                        inputContent="Drop files here or click to upload."
                        styles={{
                          dropzone: { width: '100%', height: 50 },
                          dropzoneActive: { borderColor: 'green' },
                        }}
                      />
                    </div>
                  </Col>
                  <Col sm="12" className="m-3">
                    <FormGroup className="mb-0">
                      <div className="btn-showcase">
                        <Button color="primary" type="submit">{Post}</Button>
                        <Link to={`${process.env.PUBLIC_URL}/app/blog/blog-list`}>
                          <Button color="light" type="reset">{Discard}</Button>
                        </Link>
                      </div>
                    </FormGroup>
                  </Col>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default withRouter(BlogPost);