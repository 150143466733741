import React, { Fragment,useState,useEffect } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
// import Dropzone from 'react-dropzone-uploader'
import {Container,Row,Col,Card,CardBody,Form,FormGroup,Label,Input,Button} from 'reactstrap'
import DatePicker from "react-datepicker";
import {useForm} from 'react-hook-form'
import {withRouter,Link} from 'react-router-dom'
import {Add,Cancel, Data} from '../../../constant'
import Ckeditor from 'react-ckeditor-component'
import { serialize } from 'object-to-formdata';
import {ApiBotGetCategory,ApiBotGetOne,ApiBotEdit,ApiBlogDelBot} from '../../../api/bot';
import { toast } from 'react-toastify';
import SweetAlert from 'sweetalert2'

const OldBot = (props) => {
    const { register, handleSubmit, errors } = useForm();
    const [txtOff, setTxtOff] = useState();
    const [txtOverwrite, setTxtOverwrite] = useState();
    const [txtTutorial, setTxtTutorial] = useState();
    const [txtUpdate, setTxtUpdate] = useState();
    const [categryies, setTaskList] = useState([]);
    const [allData, setBotData]=useState({
      title:'',
      url:'',
      publish:'',
    })

    useEffect(async() => {
      handeleSetList();
      handeleSetAll();
    },[])
  
    const handeleSetAll=async()=>{
      let data = await ApiBotGetOne(props.match.params.id);
      console.log(data)
      setBotData(data);
      setTxtOff(data.txt_off);
      setTxtOverwrite(data.txt_overview);
      setTxtTutorial(data.txt_tutorial);
      setTxtUpdate(data.txt_updates);
    }

    const handeleSetList=async()=>{
      let dataCat = await ApiBotGetCategory();
      setTaskList(dataCat);
    }
    

    const handleSetTxt = e => {
      setTxtOff(e.editor.getData());
    };
    const handleSetTxtOverwrite = e => {
      setTxtOverwrite(e.editor.getData());
    };
    const handleSetTxtTutorial= e => {
      setTxtTutorial(e.editor.getData());
    };
    const handleSetTxtUpdate = e => {
      setTxtUpdate(e.editor.getData());
    };
    
    // const handleGetCategory = async() => {
    //   let categor=await ApiBotGetCategory();
    //   setCategories(categor);
    // };


    const AddProject =async data => {
      if (data !== '') {
        data.txtOff=txtOff;
        data.txtOverwrite=txtOverwrite;
        data.txtTutorial=txtTutorial;
        data.txtUpdate=txtUpdate;
        data.id=allData.id;
        console.log(data)
        let newForm=serialize(data);
        let result=await ApiBotEdit(newForm);
        if(result.err){
          setTimeout(() => {
            toast.error(result.err);
          }, 200);
        }
        else{
            setTimeout(() => {
              toast.success(result.msg);
            }, 200);
        }
      } else {
        errors.showMessages();
      }
    };

    const removeBot=()=>{
      SweetAlert.fire({
        title: 'Are you sure?',
        text: "Once deleted, you will not be able to recover this bot!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
      }).then(async(result) => {
        if(result.value){
          let delResult=await ApiBlogDelBot(allData.id);
          if (delResult.success) {
            SweetAlert.fire(
              'Deleted!',
              'The bot has been deleted.',
              'success'
            );
            props.history.push(`../bot-list`);  
          }
          else{
            setTimeout(() => {
              toast.error(delResult.err);
            }, 200);
          }
        }
        else {
          SweetAlert.fire(
            'Your imaginary bot is safe!'
          )
        }
      })
    }

    return (
        <Fragment>
        <Breadcrumb parent="Bot" title="Create Bot" /> 
        <Container fluid={true}>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Form className="theme-form" onSubmit={handleSubmit(AddProject)}>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Bot title</Label>
                            <Input className="form-control" type="text"  name="title" defaultValue={allData.title} placeholder="Bot titile *" innerRef={register({ required: true })} />
                            <span style={{ color: "red" }}>{errors.title && 'Title is required'}</span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Bot URL</Label>
                            <Input className="form-control" type="text" name="url" defaultValue={allData.url} placeholder="Bot URL" innerRef={register({ required: true })}/>
                            <span style={{ color: "red" }}>{errors.client_name && 'Client Name is required'}</span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="4">
                          <FormGroup>
                            <Label>Bot Status</Label>
                            <Input type="select"  name="status" placeholder="Select Status" className="form-control digits" innerRef={register({ required: true })}>
                              <option value="0" selected={allData.publish===0}>Unpublish</option>
                              <option value="1" selected={allData.publish===1}>Publish</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup>
                            <Label>Bot Type</Label>
                            <Input type="select" name="type" placeholder="Select Type" className="form-control digits" innerRef={register({ required: true })}>
                              <option value="actions" selected={allData.type==='actions'}>Actions</option>
                              <option value="extractions" selected={allData.type==='extractions'}>Extractions</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup>
                            <Label>Bot Category</Label>
                            <Input type="select" name="category"  placeholder="Select Category" className="form-control digits" innerRef={register({ required: true })}>
                              <option  disabled selected>Select Category</option>
                              {categryies.lenght !== 0 ?
                                categryies.map((category, index) => {
                                  return (<option key={index} selected={allData.cat_id===category.id}  value={category.id}>{category.title}</option>)
                                })
                                :<option value="0"  disabled>No category found</option>
                              }
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="4">
                          <FormGroup>
                            <Label>Create Date: </Label>
                            <p>{allData.crt_date}</p>
                            {/* <DatePicker className="datepicker-here form-control"  selected={'08/11/2020'} dateFormat="dd/MM/yyyy" onChange={handleStartDate} /> */}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Bot Describtion</Label>
                            <Input  type="textarea" className="form-control"  defaultValue={allData.description} name="description" rows="3" innerRef={register({ required: true })}/>
                            <span style={{ color: "red" }}>{errors.description && 'Describtion is required'}</span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                              <FormGroup>
                                <Label>Overview</Label>
                                <Ckeditor
                                  activeclassName="p10"
                                  content={txtOverwrite}
                                  events={{
                                    "change":handleSetTxtOverwrite
                                  }}
                                />
                              </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                              <FormGroup>
                                <Label>Tutorial</Label>
                                <Ckeditor
                                  activeclassName="p10"
                                  content={txtTutorial}
                                  events={{
                                    "change":handleSetTxtTutorial
                                  }}
                                />
                              </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                              <FormGroup>
                                <Label>Updates</Label>
                                <Ckeditor
                                  activeclassName="p10"
                                  content={txtUpdate}
                                  events={{
                                    "change":handleSetTxtUpdate
                                  }}
                                />
                              </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                              <FormGroup>
                                <Label>Log Out Overview</Label>
                                <Ckeditor
                                  activeclassName="p10"
                                  content={txtOff}
                                  events={{
                                    "change":handleSetTxt
                                  }}
                                />
                              </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup className="mb-0">
                              <Button color="success" className="mr-3">Save</Button>
                              <Button color="danger" onClick={()=>removeBot()}>Delete</Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Fragment>
    );
}

export default withRouter(OldBot);