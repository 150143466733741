import React, { Fragment,useState,useEffect } from 'react';
import {withRouter} from 'react-router-dom'
import Breadcrumb from '../../layout/breadcrumb'
import Ckeditor from 'react-ckeditor-component'
import { Typeahead } from 'react-bootstrap-typeahead';
import {useForm} from 'react-hook-form'
import Dropzone from 'react-dropzone-uploader'
import { serialize } from 'object-to-formdata';
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button } from "reactstrap"
import {PostEdit,Title,Type,Category,Content,Post,Discard,Text,Audio,Video,Image} from "../../constant";
import { toast } from 'react-toastify';
import {ApiBlogUpdatePost,ApiBlogGetTag,ApiBlogGetOne,ApiBlogTagByList,ApiBlogDelPost} from '../../api/blog.jsx'
import SweetAlert from 'sweetalert2'

const BlogPostOne = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [content, setContent] = useState("");
  const [pic, setPic] = useState("");
  const [allData, setAllData]=useState({})
  
  const [tagList, setTagList] = useState([]);
  const [tags, setTags] = useState("");
  const [tagOld, setTagOld] = useState([]);
  

  useEffect(() => {
    handelSetAll();
    handelUpdateTagList();
  },[])

  const handelSetAll=async()=>{
    const allDatat=await ApiBlogGetOne(props.match.params.id);
    setAllData(allDatat);
    setContent(allDatat.contnet);
    const tag=await ApiBlogTagByList(allDatat.tags);
    setTagOld(tag);
    removeAdded(tag);
  }
  
  //tagList tagOld
  const handelUpdateTagList=async()=>{
    const tags=await ApiBlogGetTag();
    setTagList(tags);
  }
  

  const removeAdded=async(a2)=>{
    let a1=await ApiBlogGetTag();
    const list1Ids = new Set(a2.map(({ id }) => id));
    const combined = [
      ...a1.filter(({ id }) => !list1Ids.has(id))
    ];
    setTagList(combined)
  }
  
  const handleSetTag=(selected)=>{
    let tags=new Array();
    for(let i=0;i<selected.length;i++){
      tags.push(`-${selected[i].id}-`)
    }
    let tagsStr=tags.join(",");
    setTags(tagsStr);
  }
  const handleSetTagOld=(list)=>{
    let tags=new Array();
    for(let i=0;i<list.length;i++){
      tags.push(`-${list[i].id}-`)
    }
    let tagsStr=tags.join(",");
    return tagsStr;
  }

  const handleSetContent=(e)=>{
    setContent(e.editor.getData())
  }

  const handleChangeStatus=(e)=>{
    setPic(e.file);
  }

  const removeOldTag=(e,id)=>{
    let dubliate=tagOld;
    for(let i=0; i<dubliate.length;i++){
      if(dubliate[i].id===id){
        dubliate.splice(i,1);
      }
    }
    setTagOld(dubliate);
    removeAdded(dubliate);
  }

  const UpdatePost =async data => {
    if (data !== '') {
      if(content==="")return setTimeout(() => { toast.error('Fill All Reuired Field'); }, 200);
      let tagOldS=handleSetTagOld(tagOld)
      data.tags=tagOldS!==""?tagOldS+`${tags!==""?',':''}`+tags:tags;
      data.content=content;
      data.id=allData.id;
      const newForm=serialize(data);
      if(pic!=="")newForm.append('pic', pic);
      let result=await ApiBlogUpdatePost(newForm);
      if(result.err){
        setTimeout(() => {
          toast.error(result.err);
        }, 200);
      }
      else{
        setTimeout(() => {
          toast.success(result.msg);
        }, 200);
      }
    }
    else {
      errors.showMessages();
    }
  }

  const removePost=()=>{
    SweetAlert.fire({
      title: 'Are you sure?',
      text: "Once deleted, you will not be able to recover this post!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then(async(result) => {
      if(result.value){
        let delResult=await ApiBlogDelPost(allData.id);
        if (delResult.success) {
          SweetAlert.fire(
            'Deleted!',
            'The post has been deleted.',
            'success'
          );
          props.history.push(`../blog-list`);  
        }
        else{
          setTimeout(() => {
            toast.error(delResult.err);
          }, 200);
        }
      }
      else {
        SweetAlert.fire(
          'Your imaginary post is safe!'
        )
      }
    })
  }

  return (
    <Fragment>
      <Breadcrumb parent="Blog" title="Edit Post" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="add-post">
                <Form className="row needs-validation" onSubmit={handleSubmit(UpdatePost)}>
                  <Col sm="12">
                    <FormGroup>
                      <Label for="validationCustom01">{Title}:</Label>
                      <Input className="form-control" defaultValue={allData.title} name="title" id="validationCustom01" type="text" placeholder="Post Title" innerRef={register({ required: true })} />
                    </FormGroup>
                    <FormGroup>
                      <Label for="validationCustom02">URL:</Label>
                      <Input className="form-control" name="url" defaultValue={allData.url} id="validationCustom02" type="text" placeholder="Post URL" innerRef={register({ required: true })} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Header Text</Label>
                        <Input  type="textarea" className="form-control" defaultValue={allData.headerText} name="headerText" rows="3" innerRef={register({ required: true })}/>
                    </FormGroup>
                    <FormGroup>
                      <div className="col-form-Label">{Category}:
                            <div className="rbt-input-wrapper" >
                            {tagOld.map((tag,i)=>(
                                <div key={i} className="rbt-token rbt-token-removeable" tabIndex="0">{tag.title}
                                  <button tabIndex="-1" aria-label="Remove" className="close rbt-close rbt-token-remove-button" type="button" onClick={(e)=>removeOldTag(e,tag.id)}>
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                            ))}
                            </div>
                            <Typeahead
                              onChange={(selected) => {
                                handleSetTag(selected);
                              }}
                              id="multiple-typeahead"
                              className="mt-2"
                              clearButton
                              allowNew={() => true}
                              labelKey="title"
                              multiple
                              options={tagList}
                              placeholder="Select Your Name...."
                            />
                      </div>
                    </FormGroup>
                    <div className="email-wrapper">
                      <div className="theme-form">
                        <FormGroup>
                          <Label>{Content}:</Label>
                          <Ckeditor 
                            activeclassName="p10"
                            content={content}
                            events={{
                              "change":handleSetContent
                            }}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </Col>
                  <Col sm="12">
                    <div className="m-0 dz-message needsclick">
                      <Dropzone
                        onChangeStatus={handleChangeStatus}
                        maxFiles={1}
                        multiple={false}
                        canCancel={false}
                        required={true}
                        inputContent="Drop files here or click to upload."
                        acceptedFiles='image/*'
                        styles={{
                          dropzone: { width: '100%', height: 50 },
                          dropzoneActive: { borderColor: 'green' },
                        }}
                      />
                    </div>
                  </Col>
                  <Col sm="4" className="mt-3">
                      <FormGroup>
                        <Label>Bot Status</Label>
                        <Input type="select"  name="status" placeholder="Select Status" className="form-control digits" innerRef={register({ required: true })}>
                          <option value="0" selected={allData.publish===0}>Unpublish</option>
                          <option value="1" selected={allData.publish===1}>Publish</option>
                        </Input>
                      </FormGroup>
                  </Col>
                  <Col sm="12">
                    <FormGroup>
                      <div className="btn-showcase">
                        <Button color="primary" type="submit">Update</Button>
                        <Button color="danger" type="reset" onClick={()=>removePost()}>Delete</Button>
                      </div>
                    </FormGroup>
                  </Col>
              </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default withRouter(BlogPostOne);